import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

export const Project = ({ name, mainImage, tags }) => {
  return (
    <Box align='start'>
      <Box overflow='hidden' borderRadius='lg' transform='translateZ(0)'>
        <GatsbyImage image={mainImage?.asset?.gatsbyImageData} alt={name} />
      </Box>
      <Text pt='4' color='gray.400'>
        {tags?.join(', ')}
      </Text>
      <Heading as='h3' size='md' fontWeight='semibold'>
        {name}
      </Heading>
    </Box>
  )
}

export const query = graphql`
  fragment Project on SanityProject {
    __typename
    tags
    name
    mainImage {
      asset {
        gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
      }
    }
  }
`
