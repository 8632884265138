import React from 'react'
import { Box, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { PortableText } from './SanityPortableText'

export const AboutMe = ({ heading, tabHeading, experiences, text, image }) => {
  return (
    <SimpleGrid columns={[1, null, 2]} templateColumns={['1fr', null, '2fr 3fr']} spacing='40px'>
      <Box>
        <Box overflow='hidden' borderRadius='lg'>
          <GatsbyImage image={image?.asset?.gatsbyImageData} alt='O mě' />
        </Box>
      </Box>
      <Box>
        <Heading as='h2' size='2xl'>
          {heading?.title}
        </Heading>
        <Box py={6}>
          <PortableText content={text?._rawContent} />
        </Box>
        <Text
          as='u'
          textUnderlineOffset='12px'
          textDecorationThickness='2px'
          color='red.500'
          fontSize='lg'
          fontWeight='medium'
        >
          {tabHeading?.title}
        </Text>

        <VStack py='6' spacing='5' align='start'>
          {experiences?.map(({ name, company, startDate, endDate }, i) => (
            <Text key={i}>
              <Box>
                <Text as='span'>{name}</Text>
                <Text as='span' color='gray.400'>
                  {' '}
                  - {company}
                </Text>
              </Box>
              <Text color='gray.400'>
                {startDate} - {endDate}
              </Text>
            </Text>
          ))}
        </VStack>
      </Box>
    </SimpleGrid>
  )
}

export const query = graphql`
  fragment AboutMe on SanityAboutMe {
    _key
    __typename
    heading {
      title
      _rawSubtitle
    }
    text {
      _rawContent
    }
    tabHeading {
      title
    }
    experiences {
      company
      endDate
      name
      startDate
    }
    image {
      asset {
        gatsbyImageData(fit: FILLMAX, width: 600, height: 650, placeholder: BLURRED)
      }
    }
  }
`
