import React from 'react'
import { SimpleGrid, Box, Text } from '@chakra-ui/react'
import { graphql } from 'gatsby'

const Contact = ({ email, phone, address, ic }) => {
  return (
    <SimpleGrid columns={[1, null, null, 2]} py='5' spacing='5'>
      <Box>
        <Text as='span' color='gray.400'>
          Email:
        </Text>{' '}
        <a href={`mailto:${email}`}>{email}</a>
      </Box>
      <Box>
        <Text as='span' color='gray.400'>
          Telefon:
        </Text>{' '}
        <a href={`tel:${phone}`}>{phone}</a>
      </Box>
      <Box>
        <Text as='span' color='gray.400'>
          Adresa:
        </Text>{' '}
        {address.street}, {address.city}
      </Box>
      <Box>
        <Text as='span' color='gray.400'>
          IČ:
        </Text>{' '}
        {ic}
      </Box>
    </SimpleGrid>
  )
}

export default Contact

export const query = graphql`
  fragment Contact on SanityContact {
    __typename
    name
    email
    altEmail
    phone
    altPhone
    address {
      city
      street
      zipCode
    }
    ic
    dic
    map {
      lat
      lng
    }
  }
`
