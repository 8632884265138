import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Box } from '@chakra-ui/react'

export const ImageBlock = ({ asset, alt }) => {
  return (
    <Box overflow='hidden' borderRadius='lg'>
      <GatsbyImage image={asset?.gatsbyImageData} alt={alt} />
    </Box>
  )
}

export const query = graphql`
  fragment ImageBlock on SanityImageBlock {
    __typename
    alt
    asset {
      gatsbyImageData(fit: FILL, placeholder: BLURRED)
    }
  }
`
