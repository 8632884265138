import React from 'react'
import { Heading as ChakraHeading } from '@chakra-ui/react'
import { graphql } from 'gatsby'

export const Heading = ({ title, type, _rawSubtitle }) => {
  if (type === 'section') {
    return (
      <ChakraHeading as='h2' size='2xl' pb='20' align='center'>
        {title}
      </ChakraHeading>
    )
  }

  return (
    <ChakraHeading as='h2' size='2xl'>
      {title}
    </ChakraHeading>
  )
}

export const query = graphql`
  fragment Heading on SanityHeading {
    type
    title
  }
`
