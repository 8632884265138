import React from 'react'
import { graphql } from 'gatsby'

import { ContactForm } from './ContactForm'

const FormComponents = {
  contactForm: ContactForm,
}

export const Form = ({ type, ...rest }) => {
  const FormComponent = FormComponents[type]
  if (!FormComponent) return null
  return <FormComponent {...rest} />
}

export const query = graphql`
  fragment Form on SanityForm {
    type
    name
    emailFrom
    emailTo
    emailSubject
    successMessage
    errorMessage
    submitButton
    resetButton
  }
`
