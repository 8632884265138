import React from 'react'
import { AboutMe } from './AboutMe'
// import { Grid } from './Grid'
import { Heading } from './Heading'
import Collection from './Collection'
import { ImageBlock } from './ImageBlock'
import Contact from './Contact'
import { Form } from './Form'
import { Feature } from './Feature'
import { Project } from './Project'
import Column from './Column'

const Components = {
  SanityColumn: Column,
  // SanityGrid: Grid,
  SanityHeading: Heading,
  SanityCollection: Collection,
  SanityImageBlock: ImageBlock,
  SanityAboutMe: AboutMe,
  SanityContact: Contact,
  SanityForm: Form,
  SanityFeature: Feature,
  SanityService: Feature,
  SanityProject: Project,
}

export const resolveComponents = (items) => {
  return items
    ?.map((item, i) => {
      const Component = Components[item?.__typename]

      if (!Component) return null

      return <Component key={i} {...item} />
    })
    ?.filter(Boolean)
}
