import React from 'react'
import { graphql } from 'gatsby'
import { Grid } from './Grid'

export default function Column({ items }) {
  return (
    <Grid
      items={items}
      gridSetting={{
        spacing: 5,
        direction: 'column',
        align: 'flex-start',
      }}
    />
  )
}
export const query = graphql`
  fragment Column on SanityColumn {
    name
    items {
      __typename
      ... on SanityCollection {
        ...Collection
      }
      ... on SanityHeading {
        ...Heading
      }
      ... on SanityImageBlock {
        ...ImageBlock
      }
      ... on SanityGrid {
        ...Grid
      }
      ... on SanityContact {
        ...Contact
      }
      ... on SanityForm {
        ...Form
      }
      ... on SanityButton {
        label
        href
      }
      ... on SanityAboutMe {
        ...AboutMe
      }
    }
  }
`
