import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { Box, VStack, Text, Heading, Container } from '@chakra-ui/react'
import { BgImage } from 'gbimage-bridge'
import TextLoop from 'react-text-loop'
import Navigation from './Navigation'
import { graphql } from 'gatsby'

const Header = ({ navigation, subHeader, socialMedias }) => {
  const slide = subHeader[0]

  return (
    <BgImage image={getImage(slide.image.asset.gatsbyImageData)}>
      <Navigation items={navigation?.items || []} socialMedias={socialMedias} />
      <Container id='domu' maxW='container.xl' h='680px' pt='250px' px={12}>
        <VStack align='left' spacing={4}>
          <Text casing='uppercase' letterSpacing='widest' color='gray.400' pb={4}>
            {slide.signature}
          </Text>
          <Heading as='h1' fontSize={['3xl', '4xl', '5xl', '6xl']} fontWeight='bold'>
            {slide.title}
          </Heading>
          <TextLoop>
            {slide?.texts?.map((text, i) => (
              <Heading
                key={i}
                as='h2'
                fontSize={['3xl', '4xl', '5xl', '6xl']}
                fontWeight='bold'
                color='red.500'
              >
                {text}
              </Heading>
            ))}
          </TextLoop>
          <Heading as='h2' fontSize={['3xl', '4xl', '5xl', '6xl']} fontWeight='bold'>
            {slide.subtitle}
          </Heading>
        </VStack>
      </Container>
    </BgImage>
  )
}

export default Header

export const query = graphql`
  fragment Header on SanityHeader {
    navigation {
      ...Navigation
    }
    contacts {
      ...Contact
    }
    socialMedias {
      name
      type
      url
    }
  }
`
