import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const validationSchema = Yup.object({
  name: Yup.string().required('Vyplňte vaše jméno'),
  email: Yup.string()
    .email('Zadejte emailovou adresu ve správném tvaru')
    .required('Vyplňte emailovou adresu'),
  message: Yup.string().required('Vyplňte zprávu'),
})

export const ContactForm = ({ successMessage, errorMessage, submitButton }) => {
  return (
    <Formik
      initialValues={{ name: '', email: '', subject: '', message: '' }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const response = await fetch('/.netlify/functions/contact', {
          method: 'POST',
          // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          // body: encode({ 'form-name': 'contact-demo', ...values }),
          body: JSON.stringify(values),
        })

        if (response.status === 200) {
          actions.resetForm()
          actions.setStatus({ type: 'success', message: successMessage })
        } else {
          actions.setStatus({ type: 'error', message: errorMessage })
        }
      }}
      render={({ status, setStatus }) => (
        <Form>
          <VStack align='start' spacing='24px'>
            {status?.type && (
              <Alert status={status.type} variant='solid'>
                <AlertIcon />
                {status.message}
                <CloseButton
                  position='absolute'
                  right='8px'
                  top='8px'
                  onClick={() => setStatus({})}
                />
              </Alert>
            )}

            <Field name='name'>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <Input {...field} id='name' placeholder='Jméno *' />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='email'>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.email && form.touched.email}>
                  <Input {...field} id='email' type='email' placeholder='E-mail *' />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='subject'>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.subject && form.touched.subject}>
                  <Input {...field} id='subject' type='subject' placeholder='Předmět' />
                  <FormErrorMessage>{form.errors.subject}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='message'>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.message && form.touched.message}>
                  <Textarea {...field} id='message' type='message' placeholder='Zpráva *' />
                  <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <input type='hidden' name='bot-field' />
            <input type='hidden' name='form-name' value='contact' />

            <Button
              type='submit'
              size='lg'
              px='10'
              fontWeight='normal'
              color='white'
              bg='red.500'
              transitionProperty='all'
              transitionDuration='.4s'
              transitionTimingFunction='ease-in-out'
              _hover={{
                transform: 'translateY(-4px)',
              }}
            >
              {submitButton}
            </Button>
          </VStack>
        </Form>
      )}
    />
  )
}
