import React from 'react'
import { useStyleConfig, Center } from '@chakra-ui/react'

function SocialButton(props) {
  const { variant, icon, ...rest } = props

  const styles = useStyleConfig('SocialButton', { variant })
  const size = variant === 'header' ? '36px' : '50px'

  return (
    <Center w={size} h={size} __css={styles} {...rest}>
      {icon}
    </Center>
  )
}

export default SocialButton
