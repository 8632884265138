import { Box, Heading, Icon, Text } from '@chakra-ui/react'
import * as ReactIcons from 'react-icons/fi'
import React from 'react'
import { PortableText } from './SanityPortableText'
import { graphql } from 'gatsby'

export const Feature = ({ title, icon, _rawDescription }) => {
  return (
    <Box
      w='100%'
      p={6}
      borderRadius='lg'
      bg='gray.700'
      role='group'
      transitionProperty='all'
      transitionDuration='.4s'
      transitionTimingFunction='ease-in-out'
      _hover={{
        bgGradient: 'linear(to-r, red.600, red.500)',
        transform: 'translateY(-4px)',
      }}
      align='center'
    >
      <Icon
        as={ReactIcons[icon]}
        w={54}
        h={54}
        strokeWidth='1px'
        color='red.500'
        transitionProperty='all'
        transitionDuration='.4s'
        transitionTimingFunction='ease-in-out'
        _groupHover={{ color: 'white' }}
      />
      <Heading as='h3' size='md' fontWeight='normal' py='4' color='gray.100'>
        {title}
      </Heading>
      <Text color='gray.300' _groupHover={{ color: 'white' }}>
        <PortableText content={_rawDescription} />
      </Text>
    </Box>
  )
}

export const query = graphql`
  fragment Feature on SanityFeature {
    __typename
    title
    icon
    _rawDescription
  }
`
