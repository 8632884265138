import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Section from '../components/Section'

export default function PageIndex({ pageContext, data }) {
  const page = data?.allSanityPage?.nodes?.[0]
  const siteSettings = data?.allSanitySiteSettings?.nodes[0]
  const { header, subHeader, sections } = page

  return (
    <>
      <Helmet>
        <title>Webové studio IziArt</title>
        <meta name='description' content='Tvorba webových stránek Třinec, Ostrava a okolí' />
        <meta name='author' content='Rostislav Kulla' />
        <html lang='cs' />
      </Helmet>

      <Header {...(header || siteSettings.header)} subHeader={subHeader} />
      {sections.map((section) => (
        <Section key={section.id} {...section} />
      ))}
      <Footer footer={siteSettings.footer} />

      {/*  <div className='backto-top'>*/}
      {/*    <ScrollToTop showUnder={160}>*/}
      {/*      <ReactIcons.FiChevronUp />*/}
      {/*    </ScrollToTop>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    allSanityPage(filter: { id: { eq: $id } }) {
      nodes {
        id
        title

        header {
          ...Header
        }
        subHeader {
          ... on SanityHero {
            __typename
            title
            subtitle
            texts
            signature
            image {
              asset {
                gatsbyImageData(width: 1440, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          ... on SanitySlider {
            __typename
            id
            name
          }
        }
        sections {
          ...Section
        }
      }
    }
    allSanitySiteSettings {
      nodes {
        title
        description
        mainPage {
          id
        }
        header {
          ...Header
        }
        footer {
          ...Footer
        }
      }
    }
  }
`
