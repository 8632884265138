import React from 'react'
import { graphql } from 'gatsby'
import { Grid } from './Grid'

const Collection = (collection) => {
  const { type } = collection
  const gridSetting = { ...collection.gridSetting, spacing: '30px', spacingY: '60px' }
  const items = collection?.[type] || []
  return <Grid gridSetting={gridSetting} items={items} />
}

export default Collection

export const query = graphql`
  fragment Collection on SanityCollection {
    type
    gridSetting {
      numberOfColumns
      layout
    }
    services {
      __typename
      title
      icon
      _rawDescription
    }
    projects {
      ...Project
    }
    features {
      ...Feature
    }
  }
`
