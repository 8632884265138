import React from 'react'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { resolveComponents } from './componentsResolver'

export const Grid = ({ gridSetting, items }) => {
  const setting = {
    numberOfColumns: 1,
    direction: 'row',
    spacing: '20px',
    align: 'flex-start',
    ...gridSetting,
  }

  const numberOfColumns = setting?.numberOfColumns
  const components = resolveComponents(items)
  return (
    <Box>
      <SimpleGrid
        className='blocksBuilder'
        columns={[1, null, numberOfColumns > 1 ? 2 : null, numberOfColumns]}
        {...setting}
      >
        {components}
      </SimpleGrid>
    </Box>
  )
}

export const query = graphql`
  fragment Grid on SanityGrid {
    _key
    __typename
    gridSetting {
      numberOfColumns
      layout
    }
    items {
      __typename
      ... on SanityCollection {
        ...Collection
      }
    }
  }
`
