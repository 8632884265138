import React from 'react'
import ReactPortableText from 'react-portable-text'

export const PortableText = ({ content }) => (
  <div>
    <ReactPortableText
      // Pass in block content straight from Sanity.io
      content={content}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={
        {
          // h1: (props) => <h1 style={{ color: 'red' }} {...props} />,
          // li: ({ children }) => <li className='special-list-item'>{children}</li>,
          // someCustomType: PortableText,
        }
      }
    />
  </div>
)
