import React from 'react'
import { Box, Container } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { Grid } from './Grid'

export default function Section({ slug, theme, columns }) {
  return (
    <Box id={slug?.current} bg={theme === 'light' ? 'gray.700' : ''}>
      <Container maxW='container.xl' py={20} px={12}>
        <Grid
          gridSetting={{ numberOfColumns: columns?.length || 1, spacing: '40px' }}
          items={columns}
        />
      </Container>
    </Box>
  )
}

export const query = graphql`
  fragment Section on SanitySection {
    slug {
      current
    }
    fullWidth
    fullHeight
    theme
    columns {
      __typename
      ...Column
    }
  }
`
