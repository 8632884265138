import React from 'react'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { HStack, SimpleGrid, Box, Text } from '@chakra-ui/react'
import SocialButton from './SocialButton'
import { graphql } from 'gatsby'
import { PortableText } from './SanityPortableText'

const SOCIAL_MEDIA_ICONS = {
  facebook: <FaFacebookF />,
  linkedin: <FaLinkedinIn />,
}

const Footer = ({ footer }) => {
  const { socialMedias, _rawCopyright } = footer || {}
  return (
    <SimpleGrid columns={[1, null, 3]} height='120px' px={12} spacing={5} alignItems='center'>
      <Box />
      <HStack spacing={4} justify='center'>
        {socialMedias?.map((val, i) => (
          <a key={i} href={val.url} aria-label={val.type}>
            <SocialButton icon={SOCIAL_MEDIA_ICONS[val.type]} />
          </a>
        ))}
      </HStack>
      <Text fontSize='sm' color='gray.400' align={['center', null, 'right']}>
        <PortableText content={_rawCopyright} />
      </Text>
    </SimpleGrid>
  )
}
export default Footer

export const query = graphql`
  fragment Footer on SanityFooter {
    name
    _rawCopyright
    socialMedias {
      type
      url
    }
  }
`
